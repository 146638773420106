<template>
    <div class="education-contain">
        <el-scrollbar class="contain-box">
            <div class="top-box">
                <left-tab/>
                <div class="top-title">
                    <div class="title-box">
                        <div class="title-text">{{title}}</div>
                        <div class="title-info">{{description}}</div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="main-box-contain box1">
                    <div class="top-card">
                        <div class="top-card-contain">
                            <div class="card-title">平台概述</div>
                            <div class="divide-line">
                                <div class="left-line"></div>
                                <div class="right-line"></div>
                            </div>
                            <div class="card-text">
                                信息化教学平台实现教学、实训、实战、考试、测评等教学活动的互联网化，并利用互联网的同步性实现教学内容与行业需求无缝连接。信息化教学平台具有强大的数据收集功能，帮助学校实现专业教学的数据化，通过收集学生的实训成绩、实战成绩、考试成绩、在线阅读时长等数据，为学校构建有效的、灵活的、体系化的人才能力多维模型，根据人才能力多维模型帮助教师针对性地补齐学生能力短板、优化教学内容与模式、提供学习和创业指导等，大大提升了教学效果的可视化和人才培养质量。
                            </div>
                            <div class="img-box">
                                <img src="../../../assets/images/education/informat-platform.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom-card">
                        <div class="box-title">平台功能</div>
                        <div class="card-item-box">
                            <div :class="item.id % 2===0?'card-item right':'card-item left'" v-for="item in cardList"
                                 :key="item.image">
                                <div class="left-card-box">
                                    <div class="left-imgBox">
                                        <img src="../../../assets/images/education/bubble.png" alt="">
                                        <span>{{item.title}}</span>
                                    </div>
                                    <div class="card-item-title">{{item.name}}</div>
                                    <div class="card-item-content">{{item.content}}</div>
                                </div>
                                <div class="card-img">
                                    <img :src="item.image" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import leftTab from "@/components/education/leftTab";
    import OfficialFooter from '@/components/OfficialFooter';

    export default {
        name: "Informatization",
        components: {
            leftTab,
            OfficialFooter
        },
        data() {
            return {
                title: '一课云平台',
                description: "将混合式教学、翻转课堂等新教育模式充分与大数据、人工智能等新技术结合，提升信息化与数字化教学水平，提升实战教学品质。",
                cardList: [
                    {
                        id: 1,
                        title: '教',
                        name: '打造资源库平台',
                        content: '一课云运用互联网云平台技术与混合式学习手段将教理实教材、实训任务书、教学课件、实训系统、MG动画微课、知识模块、素材库、案例库、教案、考试、相关文章等有序关联，解决教师备课难、实战能力薄弱的痛点，同时让上课更有趣、更方便、更实战，还能打造电子商务专业信息化教学资源库，大大提升了老师的课堂教学效果和学生学习兴趣。',
                        image: require('../../../assets/images/education/i-card1.png')
                    },
                    {
                        id: 2,
                        title: '学',
                        name: '打造在线学习平台',
                        content: '“一课云”还依托一课教育优质的资源构建在线学习平台，平台使用账号登录方式，支持教学课堂视频教学、学生课后自学。平台拥有丰富的在线课程，包括电商基础技能学习、创业教育学习、职场提升训练、考试考证、互联网知识学习等。同时，学生还可以结合自身学习兴趣和就业方向加入学习成长计划，如营销、文案、美工、运营等方向，按照系统预设的学习路径逐步掌握专业知识与技能。',
                        image: require('../../../assets/images/education/i-card2.png')
                    },
                    {
                        id: 3,
                        title: '做',
                        name: '打造项目库平台',
                        content: '提高学生的实践能力是应用型专业的核心问题，我们在课程开发中秉持“有课程必有实训”的原则，并将实训指导书有序地放进项目库平台，并在平台中搭配对应的实训软件。教师在完成理论教学后，利用项目库平台给学生分配相应的实训任务，让学生以“实践检验理论”，提高学生实践动手能力，充分理解和掌握相应知识与技能。同时，项目库平台采用在线提交和在线评分的形式，通过对比平均分，可以直观看出学生的学习效果，从而指导教师改进教学方式与内容。',
                        image: require('../../../assets/images/education/i-card3.png')
                    },
                    {
                        id: 4,
                        title: '测',
                        name: '打造智能考试平台',
                        content: '“一课云”还包含智能考试平台，能够给学校提供一站式考试平台和技能评测系统，全方位地、多方面地评价学生的岗位技能水平、综合实践能力以及就业能力。平台支持教师自由创建题目和按照课程章节创建题目，所有的试题汇集成为习题库，教师可以按需自由组卷，生成课堂小测、期中考试、期末考试等多种场景的试卷。通过智能考试平台，能够帮助教师快速检测学生学习效果。',
                        image: require('../../../assets/images/education/i-card4.png')
                    },
                    {
                        id: 5,
                        title: '评',
                        name: '打造资源库平台',
                        content: '一课云运用互联网云平台技术与混合式学习手段将教理实教材、实训任务书、教学课件、实训系统、MG动画微课、知识模块、素材库、案例库、教案、考试、相关文章等有序关联，解决教师备课难、实战能力薄弱的痛点，同时让上课更有趣、更方便、更实战，还能打造电子商务专业信息化教学资源库，大大提升了老师的课堂教学效果和学生学习兴趣。',
                        image: require('../../../assets/images/education/i-card5.png')
                    },
                ]
            }
        },
        methods: {
            goToDetail() {
                this.$router.push('/education/test')
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-contain {
        height: 100%;
        width: 100%;

        .contain-box {
            height: 100%;
            width: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-box {
            width: 100%;
            height: 616px;
            position: relative;
            background-image: url("../../../assets/images/education/informatization_platform.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #ffffff;

            .top-title {
                width: 1150px;
                margin: 0 auto;
                padding-top: 110px;

                .title-box {
                    padding: 0 350px 0 110px;

                    .title-text {
                        font-size: 45px;
                        color: #FFFFFF;
                    }

                    .title-info {
                        width: 640px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }

        .main-box {
            width: 100%;

            .main-box-contain {
                background-color: #fff;

                .box-title {
                    text-align: center;
                    font-size: 30px;
                    color: #333333;
                }

                &.box1 {
                    background-image: url("../../../assets/images/education/circle.png"), url("../../../assets/images/about/lift-hand.jpg");
                    background-repeat: no-repeat no-repeat;
                    background-size: contain;
                    background-position: top, bottom;
                    position: relative;

                    .top-card {
                        width: 1150px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        position: absolute;
                        top: -280px;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);

                        .top-card-contain {
                            padding: 50px 88px;

                            .card-title {
                                font-size: 30px;
                                color: #333333;
                            }

                            .divide-line {
                                margin-top: 27px;
                                display: flex;

                                .left-line {
                                    width: 30px;
                                    height: 2px;
                                    background: #584EEE;
                                }

                                .right-line {
                                    width: 30px;
                                    height: 2px;
                                    background: #F36F21;
                                }
                            }

                            .card-text {
                                margin-top: 40px;
                                font-size: 16px;
                                color: #333333;
                                line-height: 48px;
                            }

                            .img-box {
                                text-align: center;
                                margin-top: 60px;
                            }

                        }
                    }


                    .bottom-card {
                        width: 1150px;
                        margin: 0 auto;
                        padding-top: 700px;
                        padding-bottom: 100px;

                        .card-title {
                            text-align: center;
                            font-size: 28px;
                            color: #333333;
                        }

                        .card-item-box {
                            display: flex;
                            flex-direction: column;

                            .card-item {
                                margin-top: 80px;
                                display: flex;
                                justify-content: space-between;

                                &.left {
                                    .left-imgBox {
                                        position: relative;

                                        span {
                                            font-size: 28px;
                                            color: #FFFFFF;
                                            position: absolute;
                                            left: 46px;
                                            top: 34px;
                                        }
                                    }

                                    .card-item-content {
                                        width: 384px;
                                    }
                                }

                                &.right {
                                    flex-direction: row-reverse;

                                    .left-imgBox {
                                        position: relative;
                                        text-align: right;

                                        span {
                                            font-size: 28px;
                                            color: #FFFFFF;
                                            position: absolute;
                                            right: 46px;
                                            top: 34px;
                                        }
                                    }

                                    .card-item-title {
                                        text-align: right;
                                    }

                                    .card-item-content {
                                        width: 408px;
                                    }
                                }
                            }

                            .card-img {
                                text-align: center;
                            }

                            .card-item-title {
                                font-size: 18px;
                                color: #222222;
                                margin-top: 40px;
                            }

                            .card-item-content {
                                width: 310px;
                                font-size: 12px;
                                font-weight: 300;
                                color: #666666;
                                line-height: 30px;
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>